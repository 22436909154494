import React from 'react';
import Link from 'next/link';
import Image from 'next/image';


const Footer = () => {
    let currentYear = new Date().getFullYear();
    return (
        <footer className="footer-area-new">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-6 col-sm-6">

                        <div className="single-footer-widget">
                            <div className="logo">
                                <a href="/">
                                    <Image src="/images/white-logo.png" alt="simplify data - Logo"
                                        width={40}
                                        height={40} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-header">Unsere Dienstleistungen</div>

                            <ul className="support-lists">
                                <li>
                                    <Link href="/app-development/">
                                        App Development
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/web-development/">
                                        Web Development
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/software-development/">
                                        Software Development
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/it-consulting/">
                                        IT-Consulting KMU
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/business-analytics/">
                                        Business Analytics
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/kuenstliche-intelligenz/">
                                        KI & ML
                                    </Link>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-header">Service & Rechtliches</div>

                            <ul className="support-lists">
                                <li>
                                    <Link href="https://ticket.simplify-data.de" target="_blank">
                                        Ticketsystem
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/impressum">
                                        Impressum
                                    </Link>
                                </li>

                                <li>
                                    <Link href="/datenschutz">
                                        Datenschutz
                                    </Link>
                                </li>
                                {/** #temp
                            <li>
                                <Link href="/jobs">
                                    <a>Stellenangebote</a>
                                </Link>
                            </li>
                            **/}

                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-header">Kontakt</div>

                            <ul className="support-lists">
                                <li>Simplify Data UG (haftungsbeschränkt)</li>
                                <li>Ostring 183, 24143 Kiel</li>
                                <li>Telefon: <span><Link href="tel://+4943170577024">0431 705 770 24</Link></span></li>
                                <li>Email: <span><Link href="mailto:info@simplify-data.de">info@simplify-data.de</Link></span></li>
                            </ul>
                            <ul className="social">
                                <li>
                                    <Link href="tel://+4943170577024" target="_blank" title='Telefon'>
                                        <i className="bx bx-mobile"></i>
                                    </Link>
                                </li>

                                <li>
                                    <Link href="mailto:info@simplify-data.de" target="_blank" title='E-Mail'>
                                        <i className="bx bx-mail-send"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        href="https://www.linkedin.com/company/simplify-data-ug/" title='LinkedIn'
                                        target="_blank">
                                        <i className="bx bxl-linkedin"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://t.me/SimplifyData_bot" target="_blank" title='Telegram'>
                                        <i className="bx bxl-telegram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://twitter.com/simplify_data" target="_blank" title="Twitter">
                                        <i className="bx bxl-twitter"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://www.instagram.com/simplify.data/" target="_blank" title='Instagram'>
                                        <i className="bx bxl-instagram"></i>
                                    </Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-areas">
                <p>Copyright &copy; {currentYear} Simplify Data UG (haftungsbeschränkt)</p>
            </div>
        </footer>
    );

}
export default Footer;