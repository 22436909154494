import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import NavigationLogo from '../../public/images/logo.svg'

const Navbar = () => {

    const [currentPath, setCurrentPath] = useState("");
    const router = useRouter();

    useEffect(() => {
        setCurrentPath(router.asPath);
    }, [router]);

    const [menu, setMenu] = React.useState(true);
    const toggleNavbar = () => {
        setMenu(!menu);
    };
    

    const classOne = menu
        ? "collapse navbar-collapse mean-menu"
        : "collapse navbar-collapse show";
    const classTwo = menu
        ? "navbar-toggler navbar-toggler-right collapsed"
        : "navbar-toggler navbar-toggler-right";


    return (
        <>
            <div id="navbar" className="navbar-area navbar-style-two is-sticky">
                <div className="main-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <Link href="/" className="navbar-brand">
                                <Image
                                    priority
                                    width={230}
                                    height={58}
                                    src={NavigationLogo}
                                    alt="Simplify Data Logo"
                                />
                            </Link>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Switch Navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">

                                    <li className="nav-item">
                                        <Link href="/" className="nav-link">
                                            Startseite
                                        </Link>
                                    </li>


                                    <li className="nav-item">
                                        <Link
                                            href="/dienstleistungen"
                                            className="nav-link"
                                        >
                                            Dienstleistungen <i className="bx bx-chevron-down"></i>
                                        </Link>

                                        <ul className="dropdown-menu">


                                            <li className="nav-item">
                                                <Link
                                                    href="/app-development"
                                                    className={`nav-link ${currentPath == "/app-development/" && "active"}`}
                                                >
                                                    App Development
                                                </Link>
                                            </li>








                                            <li className="nav-item">
                                                <Link
                                                    href="/web-development"
                                                    className={`nav-link ${currentPath == "/web-development/" && "active"}`}
                                                >
                                                    Web Development
                                                    <i className="bx bx-chevron-right float-right"></i>
                                                </Link>

                                                <ul className="dropdown-menu">
                                                    <li className="nav-item">
                                                        <Link
                                                            href="/web-development/wordpress-entwicklung"
                                                            className={`nav-link ${currentPath == "/web-development/wordpress-entwicklung" && "active"
                                                                }`}
                                                        >
                                                            Wordpress Entwicklung
                                                        </Link>
                                                    </li>

                                                    <li className="nav-item">
                                                        <Link
                                                            href="/web-development/wordpress-betreuung"
                                                            className={`nav-link ${currentPath == "/web-development/wordpress-betreuung" && "active"
                                                                }`}
                                                        >
                                                            Wordpress Betreuung
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <Link href="/software-development" className={`nav-link ${currentPath == "/software-development/" && "active"}`}>
                                                    Software Development
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link href="/it-consulting" className={`nav-link ${currentPath == "/it-consulting/" && "active"}`}>
                                                    IT-Consulting KMU
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link href="/business-analytics" className={`nav-link ${currentPath == "/business-analytics/" && "active"}`}>
                                                    Business Analytics
                                                    <i className="bx bx-chevron-right float-right"></i>
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item">
                                                        <Link
                                                            href="/business-analytics/power-bi"
                                                            className={`nav-link ${currentPath == "/business-analytics/power-bi" && "active"
                                                                }`}
                                                        >
                                                            Power BI
                                                        </Link>
                                                    </li>

                                                    <li className="nav-item">
                                                        <Link
                                                            href="/business-analytics/metabase"
                                                            className={`nav-link ${currentPath == "/business-analytics/metabase" && "active"
                                                                }`}
                                                        >
                                                            Metabase
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <Link href="/kuenstliche-intelligenz" className={`nav-link ${currentPath == "/kuenstliche-intelligenz/" && "active"}`}>
                                                    Künstliche Intelligenz
                                                    <i className="bx bx-chevron-right float-right"></i>
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item">
                                                        <Link
                                                            href="/kuenstliche-intelligenz/firmendaten"
                                                            className={`nav-link ${currentPath == "/kuenstliche-intelligenz/firmendaten" && "active"
                                                                }`}
                                                        >
                                                            Firmendaten
                                                        </Link>
                                                    </li>

                                                    <li className="nav-item">
                                                        <Link
                                                            href="/kuenstliche-intelligenz/socialmedia"
                                                            className={`nav-link ${currentPath == "/kuenstliche-intelligenz/socialmedia" && "active"
                                                                }`}
                                                        >
                                                            Social Media Analyse
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>


                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <Link href="/partner" className={`nav-link ${currentPath == "/partner/" && "active"}`}>
                                            Partner
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link href="/karriere" className={`nav-link ${currentPath == "/karriere/" && "active"}`}>
                                            Karriere
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link href="/about" className={`nav-link ${currentPath == "/about/" && "active"}`}>
                                            Über uns
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link href="/kontakt" className={`nav-link ${currentPath == "/kontakt/" && "active"}`}>
                                            Kontakt
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div >
            </div >
        </>
    );
};

export default Navbar;